<template>
  <div class="policy-track">
    <div class="top_banner">
      <img src="@/assets/images/news/banner2.jpg" alt="" />
      <div class="top_title">
        <h3>关注晓羊新闻动态</h3>
        <!-- <p>Follow Xiaoyang News - Learn Industry News</p> -->
      </div>
    </div>
    <div class="dot_area">
      <img src="@/assets/images/news/dots.png" alt="" />
      <img src="@/assets/images/news/dots.png" alt="" />
    </div>
    <ul class="menu_news">
      <li @click="$router.push('/news/group-dynamics')">
        <div class="title">
          <h3 class="color-primary">集团动态</h3>
          <!-- <p>Group Dynamics</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/trade-news')">
        <div class="title">
          <h3>行业新闻</h3>
          <!-- <p>Industry News</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/policy-track')">
        <div class="title">
          <h3>政策追踪</h3>
          <!-- <p>Policy Tracking</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/public-benefit')">
        <div class="title">
          <h3>晓羊公益行</h3>
          <!-- <p>Xiaoyang Charity Line</p> -->
        </div>
      </li>
    </ul>
    <ul class="content" v-if="lists.length">
      <li v-for="(item, index) in lists" :key="index">
        <div class="left">
          <img :src="item.header_image" alt="" />
        </div>
        <div class="right">
          <div>
            <div class="title">
              {{ item.title }}
            </div>
            <div
              class="article"
              v-html="item.content.replace(/<[^>]+>/g, '')"
            ></div>
            <div class="bottom">
              <!-- <div class="date">
              {{ $moment(item.publish_at * 1000).format('YYYY年M月DD日') }}
            </div> -->
              <!-- <el-button type="primary" size="small" @click="goDetail(item.id)"
                >了解更多 >
              </el-button> -->
              <div class="operate_btn pc">
                <el-button type="primary" @click="goDetail(item.id)"
                  >了解更多 >
                </el-button>
              </div>
              <div class="operate_btn mobile">
                <el-button
                  type="primary"
                  size="small"
                  @click="goDetail(item.id)"
                  >了解更多 >
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GroupDynamic',
  data() {
    return {
      lists: [],
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
  },
  methods: {
    getDataList() {
      this.$axios
        .post('/news/list', {
          category_id: 6,
          status: 1,
        })
        .then(res => {
          this.lists = res.list
        })
    },
    goDetail(id) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: id,
        },
      })
    },
    getChinese(content) {
      var reg =
        /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g
      let names = content.match(reg)
      let result
      if (names) {
        result = names.join('')
      } else {
        result = ''
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
%policy {
  .policy-track {
    .top_banner {
      position: relative;
      img {
        width: 100%;
      }
      .top_title {
        position: absolute;
        left: 0;
        @include px2vw('left', 268);
        width: auto;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        color: $--color-white;
        h3 {
          margin-top: 0;
          @include px2vw('font-size', 52);
          margin-bottom: 0 !important;
        }
        p {
          @include px2vw('font-size', 32);
          margin-bottom: 0;
          @include px2vw('margin-top', 20);
        }
        &:after {
          content: '';
          position: absolute;
          left: -34px;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          width: 6px;
          background-color: $--color-white;
        }
      }
    }
    .dot_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40%;
      }
    }
    .menu_news {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;
      li {
        text-align: center;
        cursor: pointer;
        h3 {
          margin: 0;
          @include px2vw('padding-left', 80);
          @include px2vw('padding-right', 80);
          @include px2vw('font-size', 36);
          border-right: 1px solid #d8d8d8;
          color: #333;
        }
        p {
          @include px2vw('font-size', 16);
        }
        &:hover h3 {
          color: $--color-primary;
        }
      }
      li:last-child h3 {
        border: none;
      }
    }
    .content {
      width: 90%;
      max-width: 1320px;
      margin: 0 auto;
      li {
        // width: 100%;
        height: 396px;
        box-shadow: 0px 25px 55px 0px rgba(142, 142, 142, 0.19);
        display: flex;
        margin-bottom: 60px;
        padding: 60px;
        img {
          height: 396px;
        }
        .right {
          padding: 0 0 0 76px;
          .title {
            font-size: 32px;
            font-weight: 600;
            color: #333333;
            line-height: 50px;
            text-align: justify;
          }
          .article {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            line-height: 31px;
            margin-top: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .bottom {
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .date {
              font-size: 18px;
              font-weight: 400;
              color: #999999;
              line-height: 27px;
              letter-spacing: 2px;
            }
            .button {
              width: 137px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              background: $--color-primary;
              box-shadow: 0px 1px 10px 0px #ff9a6c;
              border-radius: 4px;
              color: $--color-white;
              font-size: 14px;
              margin-left: 20px;
              i {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.view-pc {
  @extend %policy;
  .content li .right .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .content {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    li {
      .left {
        width: 40%;
        background-color: #f2f2f2;
        img {
          object-fit: contain;
          width: 100%;
        }
      }
      .right {
        width: 52%;
        display: flex;
        align-items: center;
        .article {
          min-height: 56px;
        }
      }
    }
  }
}
.view-mobile {
  @extend %policy;
  .policy-track {
    margin-top: 44px;
    .top_banner {
      .top_title {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .menu_news {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      // overflow-x: scroll;
      li {
        margin-bottom: 10px;
        width: 50%;
        // width: max-content;
        // min-width: 120px;
        h3 {
          font-size: 16px;
          color: #333;
        }
        p {
          font-size: 10px;
        }
      }
    }
    .content {
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      li {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        margin-bottom: 30px !important;
        background-color: $--color-white;
        width: calc((100% - 50px) / 2);
        margin-right: 10px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .left {
          height: 90px;
          overflow: hidden;
          background-color: #f2f2f2;
          img {
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            object-fit: cover;
          }
        }
        .right {
          padding: 10px 0px 0;
          .title {
            font-size: 14px !important;
            line-height: 22px !important;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            text-align: center;
          }
          .article {
            font-size: 12px;
            margin-top: 10px;
            min-height: 52px;
            display: none;
          }
          .bottom {
            justify-content: center;
            margin: 20px auto 0;
            width: 60%;
            .button {
              height: 35px;
              line-height: 35px;
              font-size: 12px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
