var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"policy-track"},[_vm._m(0),_vm._m(1),_c('ul',{staticClass:"menu_news"},[_c('li',{on:{"click":function($event){return _vm.$router.push('/news/group-dynamics')}}},[_vm._m(2)]),_c('li',{on:{"click":function($event){return _vm.$router.push('/news/trade-news')}}},[_vm._m(3)]),_c('li',{on:{"click":function($event){return _vm.$router.push('/news/policy-track')}}},[_vm._m(4)]),_c('li',{on:{"click":function($event){return _vm.$router.push('/news/public-benefit')}}},[_vm._m(5)])]),(_vm.lists.length)?_c('ul',{staticClass:"content"},_vm._l((_vm.lists),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":item.header_image,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"article",domProps:{"innerHTML":_vm._s(item.content.replace(/<[^>]+>/g, ''))}}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"operate_btn pc"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goDetail(item.id)}}},[_vm._v("了解更多 > ")])],1),_c('div',{staticClass:"operate_btn mobile"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.goDetail(item.id)}}},[_vm._v("了解更多 > ")])],1)])])])])}),0):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_banner"},[_c('img',{attrs:{"src":require("@/assets/images/news/banner2.jpg"),"alt":""}}),_c('div',{staticClass:"top_title"},[_c('h3',[_vm._v("关注晓羊新闻动态")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot_area"},[_c('img',{attrs:{"src":require("@/assets/images/news/dots.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/news/dots.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',{staticClass:"color-primary"},[_vm._v("集团动态")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("行业新闻")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("政策追踪")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("晓羊公益行")])])
}]

export { render, staticRenderFns }